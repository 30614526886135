exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-about-omlinson-js": () => import("./../../../src/pages/about-omlinson.js" /* webpackChunkName: "component---src-pages-about-omlinson-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-magnet-template-js": () => import("./../../../src/templates/magnetTemplate.js" /* webpackChunkName: "component---src-templates-magnet-template-js" */),
  "component---src-templates-plp-template-js": () => import("./../../../src/templates/plpTemplate.js" /* webpackChunkName: "component---src-templates-plp-template-js" */)
}

